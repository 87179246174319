import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import BgImage from 'timelesstime-ui/components/bg-image';

export default ({ ...props }) => {
  const { peopleBackground } = useStaticQuery(graphql`
    query peopleJumbotron {
      peopleBackground: file(name: { eq: "people-background" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            height: 500
            transformOptions: { cropFocus: NORTH }
            layout: CONSTRAINED
            quality: 90
          )
        }
      }
    }
  `);
  return (
    <BgImage
      width="100%"
      height={['300px', '400px', '500px']}
      bg={getImage(peopleBackground)}
      bgColour={null}
      {...props}
    />
  );
};
