import React from 'react';
import { graphql } from 'gatsby';
import { Container, Box } from '@chakra-ui/react';

import SEO from 'timelesstime-ui/components/seo';
import LeadInHeader from 'timelesstime-ui/components/lead-in-header';
import Html from 'timelesstime-ui/components/html';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import NewsletterSignup from 'timelesstime-ui/components/newsletter-signup';
import PageLayout from '../components/layout';
import JumbotronFeaturedEvent from '../components/jumbotron-featured-event';
import PeopleJumbotron from '../components/people-jumbotron';

const AboutPage = ({ data: { aboutPage, featuredEvent } }) => (
  <PageLayout
    leadInHeader={
      <LeadInHeader
        heading={aboutPage.heading || aboutPage.title}
        imageType="group"
        crumbs={[
          {
            url: '/about/',
            title: aboutPage.title,
          },
        ]}
      />
    }
  >
    <SEO
      title={aboutPage.title}
      keywords={aboutPage.fields.keywords}
      description={aboutPage.metaDescription}
      canonicalPath="/about/"
      thumbnail={aboutPage.jsonLdThumbnailImage}
    />

    <Container maxW="container.lg" mt={8} mb={12}>
      <Html source={aboutPage.fields.contentHtml} headerLevelStart={1} />
    </Container>

    <PeopleJumbotron />

    <Box as="aside">
      <JumbotronFeaturedEvent event={featuredEvent?.nodes?.[0]} />
    </Box>

    <Jumbotron as="aside" py={8} bg="tt.darkBlue">
      <NewsletterSignup />
    </Jumbotron>
  </PageLayout>
);

export const query = graphql`
  query AboutPageQuery($dateToday: Date!) {
    aboutPage: contentfulPage(slug: { eq: "about" }) {
      title
      slug
      heading
      metaDescription
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      fields {
        path
        url
        writtenAt
        isNew
        keywords
        contentHtml
      }
    }
    featuredEvent: allContentfulEventInstance(
      limit: 1
      sort: { fields: startDate, order: ASC }
      filter: {
        type: { in: ["Webinar", "Workshop"] }
        startDate: { gte: $dateToday }
      }
    ) {
      nodes {
        id
        title
        slug
        type
        metaDescription
        title
        locationName
        address
        ticketUrl
        informationUrl
        isSoldOut
        isOnline
        maxTicketPrice
        minTicketPrice
        ticketUrl
        latLng {
          lat
          lon
        }
        startDate
        endDate
        isPartOfSeries
        positionInSeries
        numberOfEventsInSeries
        createdAt
        featuredImage {
          ...FeaturedImage
        }
        jsonLdFeaturedImage: featuredImage {
          ...JsonLdFeaturedImage
        }
        jsonLdThumbnailImage: featuredImage {
          ...JsonLdThumbnailImage
        }
        speakers {
          id
          firstName
          lastName
          email
          jobTitle
        }
        eventbriteEventId
        fields {
          path
          url
          writtenAt
          isNew
          excerptHtml
        }
      }
    }
  }
`;

export default AboutPage;
